<template>
  <div>
    <div class="d-lg-flex justify-content-between">
      <div
        class="d-flex flex-column me-lg-2 text-center p-2 rounded-3 mt-2 bg_main_blue col-12 col-lg-6"
        @click="$router.push({ path: '/ME/wallet', query: { id: 'deposit' } })"
      >
        <div class="row justify-content-center p-3">
          <div class="col-6 home_left_img d-none d-md-block"></div>
          <div class="col-12 col-md-6 pb-3 pt-md-3 text-center">
            <span>{{ $t("text_main1") }}</span>
            <div class="my-2">
              <img src="@/assets/img/main/card_1.png" style="width: 36px" />
              <img src="@/assets/img/main/card_2.png" style="width: 36px" />
              <img src="@/assets/img/main/card_3.png" style="width: 36px" />
            </div>
            <a class="btn border_gold rounded-5 py-1 text-white">
              <span class="me-2">{{ $t("text_main2") }}</span>
              <img src="@/assets/img/main/ic_right.png" style="width: 16px" />
            </a>
          </div>
        </div>
      </div>
      <div
        class="d-lg-flex d-none flex-column ms-lg-2 text-center p-2 rounded-3 mt-2 bg_main_blue col-12 col-lg-6 justify-content-center"
        @click="this.$router.push('/VIP/introduce')"
      >
        <div class="row justify-content-center p-3">
          <div class="col-12 col-md-6 pb-3 pt-md-3 text-center">
            <p>{{ $t("text_main3") }}</p>
            <p>{{ $t("text_main4") }}</p>
            <a class="btn border_gold rounded-5 py-1 text-white">
              <span class="me-2">{{ $t("text_main5") }}</span>
              <img src="@/assets/img/main/ic_right.png" style="width: 16px" />
            </a>
          </div>
          <div class="col-6 home_left_img1 d-none d-md-block"></div>
          <!-- <div class="h-100 d-none d-md-block" style="width: 35%">
            <div class="h-100 home_left_img1"></div>
          </div> -->
        </div>
      </div>
    </div>
<!-- 
    <div>
      <div class="mt-3">
        <img
          src="@/assets/img/main/icon-crown.png"
          style="width: 24px; height: 24px"
        />
        <span class="fw-bold ms-1">{{ $t("text_classic") }}</span>
      </div>
      <div class="mt-2 d-flex">
        <div
          class="col-6 position-relative c-pointer"
          @click="this.$emit('which_type', 'casino')"
        >
          <img
            src="@/assets/img/main/bt_Casino_def.png"
            class="px-lg-2 px-1 rounded-3 w-100 casino_hover"
          />
          <span
            class="fw-bold position-absolute start-0 bottom-0 text-center w-100 pb-2"
            >{{ $t("home_text6").toUpperCase() }}</span
          >
        </div>
        <div
          class="col-6 position-relative c-pointer"
          @click="this.$emit('which_type', 'sport')"
        >
          <img
            src="@/assets/img/main/bt_Sports_def.png"
            class="px-lg-2 px-1 rounded-3 w-100 sport_hover"
          />
          <span
            class="fw-bold position-absolute start-0 bottom-0 text-center w-100 pb-2"
            >{{ $t("text_sport").toUpperCase() }}</span
          >
        </div>
        <div class="col-4 position-relative c-pointer" @click="this.$emit('which_type', 'slot')">
          <img
            src="@/assets/img/main/home-slots.jpg"
            class="px-lg-2 px-1 rounded-3 w-100"
          />
          <span
            class="fw-bold position-absolute start-0 bottom-0 text-center w-100 pb-2"
            >{{ $t("invest_text14").toUpperCase() }}</span
          >
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      css_list: "col-6 mb-2 px-lg-2 px-1",
    };
  },
  methods: {},
  mounted() {},
  created() {},
  components: {},
};
</script>
