import { createI18n } from "vue-i18n";
import en from "./en-US.json";
import ms from "./ms-my.json";
import de from "./de.json";
import es from "./es.json";
import fi from "./fi.json";
import fr from "./fr.json";
import ital from "./ital.json";
import por from "./por.json";
import vi from "./vi.json";
import py from "./py.json";
import ar from "./ar.json";
import per from "./per.json";
import ti from "./ti.json";
import jp from "./jp.json";
import cn from "./cn.json";
import ko from "./ko.json";

var nav_lan = navigator.language.toLowerCase() ?? process.env.VUE_APP_LANG;
var locale = localStorage.getItem("locale") ?? process.env.VUE_APP_LANG;

if (locale != null && locale != "" && locale != "en") {
  sessionStorage.setItem("Json", JSON.stringify(locale));
} else {
  if (nav_lan == "ms-my") {
    locale = "ms";
  } else if (nav_lan == "de" || nav_lan.includes("de-")) {
    locale = "de";
  } else if (nav_lan == "es" || nav_lan.includes("es-")) {
    locale = "es";
  } else if (nav_lan == "fil") {
    locale = "fi";
  } else if (nav_lan == "fr" || nav_lan.includes("fr-")) {
    locale = "fr";
  } else if (nav_lan == "it" || nav_lan.includes("it-")) {
    locale = "ital";
  } else if (nav_lan == "pt" || nav_lan.includes("pt-")) {
    locale = "por";
  } else if (nav_lan == "vi" || nav_lan == "vi-vn") {
    locale = "vi";
  } else if (nav_lan == "ru" || nav_lan == "ru-ru") {
    locale = "py";
  } else if (nav_lan == "ar" || nav_lan.includes("ar-")) {
    locale = "ar";
  } else if (nav_lan == "th" || nav_lan == "th-th") {
    locale = "ti";
  } else if (nav_lan == "ja" || nav_lan == "ja-jp") {
    locale = "jp";
  } else if (nav_lan == "zh" || nav_lan == "zh-cn") {
    locale = "cn";
  } else if (nav_lan == "ko" || nav_lan == "ko-kr") {
    locale = "ko";
  } else {
    locale = "en";
  }

  sessionStorage.setItem("Json", JSON.stringify(locale));
  localStorage.setItem("locale", locale);
}

const i18n = createI18n({
  legacy: false,
  locale: locale,
  fallbackLocale: process.env.VUE_APP_LANG,
  messages: {
    en: en,
    ms: ms,
    de: de,
    es: es,
    fi: fi,
    fr: fr,
    ital: ital,
    por: por,
    vi: vi,
    py: py,
    ar: ar,
    per: per,
    ti: ti,
    jp: jp,
    cn: cn,
    ko: ko,
  },
});

export default i18n;
